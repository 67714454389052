@import "variables";

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: $main-blue-color;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: $main-orange-color;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin-bottom: 5px !important;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-label {
  color: $main-blue-color;
}

.mat-form-field-subscript-wrapper {
  margin-top: 5px;
}

.mat-form-field-wrapper .mat-form-field-flex {
  display: flex;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;

  @media(max-width: 501px) {
    font-size: 13px;
  }

  .mat-form-field-infix {
    padding: 8px 0 10px;
    border-top: 0.7em solid transparent;

    @media (max-width: 500px) {
      padding: 4px 0 5px;
    }

    input {
      height: 25px;

      ::placeholder {
        color: $main-blue-color;
      }
    }
  }

  .password-eye-icon {
    border: none;
    background-color: #fff;
    outline: none;
    color: $main-blue-color;
    cursor: pointer;

    @media (max-width: 768px) {
      background-color: transparent;
    }
  }
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #F56260 !important;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #F56260;
}

.mat-form-field.mat-warn .mat-input-element, .mat-form-field-invalid .mat-input-element {
  caret-color: #F56260;
}

//field required
.mat-form-field .mat-error {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 10px;
}

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 3px;
}

//input filter
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: $main-blue-color
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: $main-blue-color;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: $main-blue-color;
  font-size: 14px;
  font-style: italic;
}

.filter-input .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  padding: 5px 0 !important;
}

.filter-input {
  flex-basis: 20%;

  .mat-form-field {
    width: 100%;
  }
}

//stripe ngx
.stripe-input {
  border: 1px solid $-medium-light-blue;
  padding: 9px;
  border-radius: 3px;
  margin: 10px 0;
  box-sizing: border-box;
  color: $footer-background;
  @media(max-width: 1440px) {
    padding: 7px;
    margin: 8px 0;

  }
}

//input autofill background
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}


// survey inputs
.mat-form-field.input-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  padding: 5px 0 !important;
}

.mat-form-field.input-field.mat-form-field-appearance-legacy .mat-form-field-label {
  font-size: 13px;
  font-weight: bold;
}

// send messages dialog inputs
.share-width .mat-form-field.message-input .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  padding: 13px 0 10px !important;
}

.mat-form-field-label {
  color: $main-blue-color;
}

//dashboard simple input
.base-input {
  border: 0;
  border-radius: 2px;
  box-shadow: 0 0 5px 1px rgb(219 234 253 / 75%);
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  box-sizing: border-box;
}
