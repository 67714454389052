@import "variables";

.auth-container {
  display: flex;
  justify-content: space-between;
  height: 100vh;
  color: $main-blue-color;

  @media (max-width: 1023px) {
    display: block;
  }

  .left-section-auth {
    align-self: center;
    margin: 0 auto;
    width: 20%;

    @media (min-width: 1441px) and (max-width: 2560px) {
      width: 20%;
    }

    @media (min-width: 1025px) and (max-width: 1440px) {
      width: 23%;
    }

    @media (min-width: 769px) and (max-width: 1024px) {
      width: 25%;
    }

    @media (max-width: 1023px) {
      width: 100%;
    }

    .auth-form {
      width: 100%;

      form {
        display: flex;
        flex-direction: column;

        @media (min-width: 501px) and (max-width: 1023px) {
          margin: 0 auto;
          width: 55%;
        }

        @media (max-width: 500px) {
          width: 85%;
          margin: 0 auto;
        }
      }
    }



  }

}

@media (max-width: 1023px) {
  .background-logo {
    width: 100%;
    background: linear-gradient(11deg, rgba(196,208,224,0.2) 11%, rgba(219,234,253,1) 100%);
    margin-bottom: 35px;
  }
}

.logo-container {

  width: 100%;
  margin: 0 auto 35px;


  @media (min-width: 501px) and (max-width: 1023px) {
    width: 55%;
    margin: 0 auto;
  }

  @media (max-width: 500px) {
    width: 85%;
    margin: 0 auto;
  }

  .logo {
    width: 100%;

    @media (max-width: 1023px) {
      margin: 30px 0;
    }

    @media (max-width: 501px) {
      margin: 25px 0;
    }
  }
}

.redirect-link {
  margin-top: 20px;
  text-align: center;

  span {
    cursor: pointer;
    font-size: 15px;
    transition: 100ms all;

    &:hover {
      color: $main-blue-color;
      text-decoration: underline;
      text-decoration-color: $main-blue-color;
      font-weight: bold;
    }

    @media (max-width: 500px) {
      font-size: 13px;
    }
  }


}




