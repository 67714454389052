@import "variables";

// mat-menu
.mat-button-focus-overlay {
  display: none !important;
}

.mat-menu-content {
  width: 165px;
  padding-top: 12px !important;
  padding-bottom: 12px !important;

  @media(max-width: 1149px) {
    width: auto;
  }

}

.mat-menu-panel {
  min-height: 55px !important;
}

.mat-menu-item {
  height: 34px !important;
  line-height: 0 !important;
  padding: 0 30px 0 20px !important;
}

.cdk-overlay-connected-position-bounding-box {
  top: 58px !important;
}

//dialogs
.mat-dialog-title.title {
  color: $main-blue-color !important;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  margin-bottom: 25px;
  font-size: 22px;
}

.width-surveys-dialog .mat-dialog-container {
  width: 1125px;
}

.width-messages-dialog .mat-dialog-container {
  width: 600px !important;
}

.width-user-dialog .mat-dialog-container {
  width: 565px !important;
}

.width-general-dialog .mat-dialog-container {
  width: 530px !important
}

.width-message-dialog {
  width: 600px !important;
}

.width-update .mat-dialog-container  {
  padding: 15px 45px 13px;

  @media (max-width: 540px) {
    padding: 14px 32px;
  }
}

.dialogs-content {
  overflow-wrap: break-word !important;
  white-space: pre-wrap;
  margin: 10px 0;
}

.center-content {
  width: 80%;
  margin: auto;
}

.actions .mat-dialog-actions {
  padding: 0;
  margin: 0;
  justify-content: flex-end !important;;
}

.action-btn .mat-focus-indicator.mat-button.mat-button-base {
  color: white;
  letter-spacing: 0.3px;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  background-color: $main-blue-color;
  transition: 300ms all;
  margin-left: 15px;
  padding: 0 22px;

  &:hover {
    background-color: $hover-blue-color;
  }

  @media(max-width: 1440px) {
    font-size: 13px;
    padding: 0 20px;
  }

}

.action-btn-cancel .mat-focus-indicator.mat-button.mat-button-base {
  letter-spacing: 0.3px;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  transition: 300ms all;
  color: $grey-color;
  border: 1px solid $grey-border-color;
  padding: 0 22px;
  font-weight: 600;

  &:hover {
    color: $hover-grey-color;
    border: 1px solid $hover-grey-color;
  }

  @media(max-width: 1440px) {
    font-size: 13px;
    padding: 0 20px;
  }
}

.mat-form-field.input-field {

  width: 45% !important;
  padding: 0 15px;

  input {
    height: 17px !important;
  }
}

//calendar from user dialogs
.mat-datepicker-toggle, .mat-datepicker-content .mat-calendar-next-button, .mat-datepicker-content .mat-calendar-previous-button {
  color: $main-blue-color !important;
}

.mat-calendar-body-selected {
  background-color: $main-blue-color !important;
  color: white;
}

//checkboxes
.mat-checkbox {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 13px;
}

.mat-checkbox-frame {
  border-color: $main-blue-color !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: $main-orange-color !important;
}

.mat-checkbox-inner-container {
  width: 13px !important;
  height: 13px !important;
}

//spinner
.mat-progress-spinner circle, .mat-spinner circle {
  stroke: $main-blue-color !important;
}

.mat-select, .mat-option {
  font-family: 'Open Sans', sans-serif !important;
}

// progress bar
.mat-progress-bar-buffer {
  background-color: #bad4f9 !important;
}

.mat-progress-bar-fill::after {
  background-color: #2473f8 !important;
}
