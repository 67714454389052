@import "variables";

table {
  width: 100%;
  box-shadow: 0 1px 3px 0 rgb(12 60 96/ 35%);
  margin: 10px 0;
  table-layout: fixed;

  th, td {
    cursor: context-menu;
  }

  .mat-row .mat-cell {
    font-size: 13.5px !important;
    padding: 3px 8px !important;
    line-height: 48px;

    @media (min-width: 0) and (max-width: 768px) {
      font-size: 12px !important;
    }

    &:first-of-type {
      padding: 3px 8px 3px 25px !important;
    }

    &:last-of-type {
      padding: 3px 25px 3px 8px !important;
    }
  }
}

.mat-table .mat-header-cell {
  color: $main-blue-color;
  font-size: 15px;
  font-family: 'Open Sans';
  font-weight: bold;
  padding: 13px !important;
  background-color: #F7F8FC;

  @media (min-width: 0) and (max-width: 768px) {
    font-size: 13px !important;
  }

  &:first-of-type {
    padding: 10px 10px 10px 25px !important;
  }

  &:last-of-type {
    padding: 10px 25px 10px 10px !important;

  }
}

.mat-row:last-of-type td.mat-cell {
  border-bottom-width: 0 !important;
}

.mat-row, mat-header-row, mat-footer-row, th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: $-medium-light-blue;
}


.content-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tooltip-class {
  background-color: #F7F8FC;
  color: $main-blue-color !important;
  font-size: 13px;
  letter-spacing: 0.3px;
  padding: 8px 12px;
  box-shadow: 0 1px 3px 0 rgb(12 60 96/ 35%);
  max-width: unset !important;
  font-weight: 500;
}

.mat-column-actions {
  text-align: center !important;
  width: 5% !important;
}
