@import "variables";

.mat-focus-indicator.btn-custom.mat-button.mat-button-base {
  padding: 6px 0;
  margin-top: 10px;

  @media (max-width: 500px) {
    padding: 3px 0;
  }
}

.orange-button {
  background: $main-orange-color;
  color: white;
}

.blue-button {
  background: $main-blue-color;
  color: white;
}

.btn-custom {
  width: 100%;
  outline: none;
  border: none;
  padding: 13px 0;
  font-size: 15px;
  background-color: $main-blue-color;
  color: white;
  cursor: pointer;
  transition: all 0.3s;
  letter-spacing: 0.5px;
  border-radius: 4px;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;

  &:hover {
    background-color: $hover-blue-color;
    color: white;
  }

  @media (min-width: 501px) and (max-width: 768px) {
    font-size: 14px;
    font-weight: bold;
  }

  @media (max-width: 501px) {
    font-size: 13px;
    margin-bottom: 45px;
  }
}


.btn-actions-container {
  align-self: center;

  .mat-focus-indicator.mat-button.mat-button-base {
    background-color: $main-blue-color !important;
    color: white !important;
    font-family: 'Open Sans', sans-serif !important;
    font-weight: bold !important;
    width: 120px !important;
    border-radius: 20px !important;
    transition: all 0.3s;

    &:hover {
      background-color: $hover-blue-color !important;
    }
  }
}

//  material
.mat-button.btn-dashboard {
  background-color: $main-blue-color ;
  color: white;
  font-family: 'Open Sans';
  font-weight: bold;
  letter-spacing: 0.2px;
  padding: 0 12px;
  border-radius: 4px;
  transition: 300ms all;


  @media(max-width: 1440px) {
    font-size: 13px;
  }

  @media(max-width: 768px) {
    font-size: 12px;
    line-height: 30px;
  }

  &:hover {
    background-color: $hover-blue-color;
  }
}
