//logo color
$main-blue-color: #315383;
$main-orange-color: #f5a87a;

//hover
$hover-blue-color: #315383e3;
$hover-orange-color: #ee7c38;

//overlay
$overlay-photos: linear-gradient(to bottom, rgba(255, 175, 142, 0.4) 35%, rgba(245, 168, 122, 0.5) 100%);

// light blue
$lightest-blue: #0F4B9F;
$-medium-light-blue: #B0C7E6;
$-medium-blue: #83B7DC;
$-pastel-light-blue: #DBEAFD;

// background
$light-blue-bg: rgba(219, 234, 253, 0.3);
$footer-background: #001C3D;

// grey
$grey-color: #505560;
$hover-grey-color: #394562;
$grey-border-color: #6e727c;

//statuses
$cancel-status-color: #e75454;
$ended-status-color: #282932;


$dark-blue-color: #001941;



