@import "variables";

.bg-status-ACTIVE {
  background-color: $main-blue-color;
}

.bg-status-PENDING {
  background-color: $main-orange-color;
}

.bg-status-NO_SUBSCRIPTION {
  background-color: $-medium-light-blue;
}

.bg-status-ENDED {
  background-color: $ended-status-color;
}

.bg-status-CANCELLED {
  background-color: $cancel-status-color;
}

.status {
  color: white;
  border-radius: 4px;
  padding: 4px 8px;
}

