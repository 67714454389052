// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$answersure-app-primary: mat-palette($mat-indigo);
$answersure-app-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$answersure-app-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$answersure-app-theme: mat-light-theme((
  color: (
    primary: $answersure-app-primary,
    accent: $answersure-app-accent,
    warn: $answersure-app-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($answersure-app-theme);
/* You can add global styles to this file, and also import other style files */

@import "styles/auth-pages";
@import "styles/inputs";
@import "styles/buttons";
@import "styles/variables.scss";
@import "styles/tables.scss";
@import "styles/material.scss";
@import "styles/statuses.scss";

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;
}

*:focus {
  outline: 0;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

//for sticky footer
.wrapper {
  min-height: calc(100vh - 60px);
  background-image: url("assets/images/shield-bg.png");
  background-repeat: no-repeat;
  background-size: 1300px;
  background-position: top -200px right -650px;

  @media (max-width: 550px) {
    min-height: calc(100vh - 130px);
  }
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-full-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-centered {
  display: flex;
  justify-content: center;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.align-flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-space-around {
  display: flex;
  justify-content: space-around;
}

.bold-text {
  font-weight: bold;
}

.text-centered {
  text-align: center !important;
}

.margin-right {
  margin-right: 20px;
}

.margin-left {
  margin-left: 20px;
}

.margin-bottom {
  margin-bottom: 10px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-25 {
  margin-bottom: 25px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-top {
  margin-top: 10px;
}

.margin-top-15 {
  margin-top: 15px;
}

.p-x-20 {
  padding: 0 20px;
}

.padding-20 {
  padding: 20px;
}

.full-width {
  width: 100%;
}

.text-color {
  color: $lightest-blue;
}

.blue-color {
  color: $main-blue-color;
}

.orange-color {
  color: $main-orange-color;
}

.align-self-centered {
  align-self: center;
}

.context-cursor {
  cursor: context-menu;
}

.cursor-pointer {
  cursor: pointer;
}

.font-17 {
  font-size: 17px;
}


.white-background {
  background-color: #ffffff;
}

.font-15 {
  font-size: 15px;
}

.font-19 {
  font-size: 19px;
}

//notify error and success
.snack-bar-error {
  background-color: #e75454;
  color: white;
}

.snack-bar-success {
  background-color: #008A60;
  color: white;
}

.snack-bar-success span {
  margin: auto !important;
  text-align: center !important;
}

.snack-bar-error span {
  margin: auto !important;
  text-align: center !important;
}

.position-relative {
  position: relative;
  z-index: 1000;
}

//admin page/ dashboard
.table-container {
  margin: 50px auto;
  padding: 0 60px 50px 60px;
  font-family: 'Open Sans', sans-serif;
}

//top table container
.top-table-container {
  margin-bottom: 50px;
}

// dashboard
.main-containers {
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgb(12 60 96 / 10%);
}

.full-width-height {
  width: 100%;
  height: 100%;
}

//actions icons /edit/delete
.actions-icons {
  margin: 0 10px 0 0;
  color: $main-blue-color;
  cursor: pointer;
}

// Os column icons
.device-icon {
  font-size: 22px;
}

.android {
  color: #83BD01;
}

.apple {
  color: #949494;
}

.dark-blue-color {
  color: $dark-blue-color;
}

//page-width

@media only screen and (max-width: 768px) {
  .page-width {
    padding: 0 18px;
  }
}


@media only screen and (min-width: 1024px) {
  .page-width {
    max-width: 950px;
  }
}

@media only screen and (min-width: 1280px) {
  .page-width {
    max-width: 1200px;
  }
}

@media only screen and (min-width: 1440px) {
  .page-width {
    max-width: 1280px;
    padding: 0;
  }
}


@media only screen and (min-width: 1920px) {
  .page-width {
    max-width: 1395px;
    padding: 0;
  }
}

@media only screen and (min-width: 1921px) {
  .page-width {
    max-width: 1600px;
  }
}

// page-width user dashboard
.page-width-dashboard {
  padding: 0 70px;

  @media (max-width: 1149px) {
    padding: 0 40px;
  }

  @media (max-width: 540px) {
    padding: 0 18px;
  }
}
